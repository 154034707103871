<template>
    <div></div>
</template>
<script>
export default {
    name: 'wx-component-auth-callback',
    async created() {
        let success = false;
        try {
            let query = this.$route.query;
            await this.$http.doApi('fw-wx-authorizer-saveAuthorizerInfo', query);
            success = true;
        } catch (e) {
            console.log(e);
        }
        if (success) {
            await this.$alert('授权成功');
        } else {
            await this.$alert('授权失败');
        }
        window.close();
    }
}
</script>

<style>

</style>